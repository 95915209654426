import React, { useState } from 'react';
import '../Styles/LeaderShipMenu.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import DiagrameImg from '../assets/images/L&M-img.png'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from '../Components/LearningDevelopmentForm';

function LeaderShipPage() {
   
  
return (
<div class="App">
   <header class="header">
   <section class='backgroung-image-business'>
       <Navbar/>
       <div class='header-about-content'>
         <h1>.</h1>
      </div>
      </section>
        {/* menu Start */}
            <SkillMenuBar/>
       {/* Header Menu End */}
   </header>
   <main class="main">
      <section class='container about-success'>
         <div class='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div class="testimonials-bg">
               <div class="container">
                     <h5> SERVICES</h5>
                     <h2><span>Leadership</span> & Management</h2>
                      <div class='row'>
                            <p>Many a times a leader or manager is thrown in to the deep end and left to swim without much training. The resilient among these may come up trumps through persistence, however these days the pace at which businesses grow, nobody has the time to let resilience and persistence shape these leaders.</p>
                            <p>A well laid out training intervention will help you achieve this at a rapid pace, the additional benefit being the creation of a robust leadership pipeline.
                     </p>
                     <p >Brix Leadership & Management programs focus on developing an authentic leader through:</p>
                     <p class='para1'>* Personal Leadership Compass</p>
                     <p class='para1'>* Personal Effectiveness, Efficiency, and Productivity</p>
                     <p class='para1'>* Personal Connect for Better Engagement</p>
                     <p class='para1'>* Aligning Individual Contribution to Collective Efforts</p>
                     <p class='para1'>* Identifying and Grooming Successors</p>         
                      </div>
                      
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
        {/* program section start */}
        
      <section class='  program-sections'>
         <div class=" thisPractices">
            <div class="container">
            <p class='pt-0'>We use an array of tools that can help with aligning your leaders to achieve your organizational goals. Some of them are: </p>
            <p class='para-Main'>Personal Style Inventory (PSI) ║ Career Driver ║ Accountability and Ownership Continuum ║ Trust Inventory ║ Clifton Strengths Finder ║ Disc</p>
               <img src={DiagrameImg} alt="ncnbdjcn"/>
            <p class='pt-5'>Our training programs offer you opportunities to acquire valuable knowledge and skills from renowned technical experts, and certification specialists We offer a wide range of courses to help you your work environment, BRiX can also develop programs to address the specific training needs of your business</p>
            </div>
         </div>
      </section>
      {/* program section end */}
   {/* program form Inner start */}
   <LearningDevelopmentForm/>
{/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}

</main>
{/* <!------footer start--------> */}
<Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default LeaderShipPage;