import React, { useState } from 'react';
import '../Styles/Business.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from '../Components/LearningDevelopmentForm';
function BusinessMenu() {
   const [isNavOpen, setIsNavOpen] = useState(false);

   const handleNavToggle = () => {
     setIsNavOpen(!isNavOpen);
   };
  
return (
<div className="App">
   <header className="header">
      <section className='backgroung-image-business'>
         <Navbar/>
         <div className='header-about-content'>
            <h1>.</h1>
         </div>
      </section>
    <SkillMenuBar/>
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                  <h5> SERVICES</h5>
                  <h2><span>Business</span> Science</h2>
                  <div className='row'>
                     <p>Every individual and department within an organization can benefit from stronger business acumen. In an increasingly competitive market, its more-and-more important to develop and refine people and process skills, in order to do any job role effectively. With an extensive range of courses featuring our subject- matter experts with high-performance delivery capability, your employees can rely on our business skills training to enable them become an effective leader, manager and a thorough professional.</p>
                     <p>Investing in BRiX business skills training can deliver better results in improving your customer service, healthier team performance, improvement in employee loyalty & retention, increased competitive advantage. After the learning employees are equipped on how to motivate teams, negotiate with vendors, accomplish conflict resolution, and communicate more effectively.
                     </p>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      {/* program form Inner start */}
      <LearningDevelopmentForm/>
      {/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default BusinessMenu;