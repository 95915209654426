import React from 'react'
import '../Styles/Domain.css'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import DomianIMg from '../assets/images/DC&G.png'
import BottomCarousel from '../Components/BottomCarousel'
import DomainCompliancesForm from './../Components/DomainCompliancesForm';

function Domain() {

return (
<div className="Apsp">
   <header className="header">
      <section className='backgroung-image-Domain'>
         <Navbar/>
         <div className='header-about-content'>
            <h1>.</h1>
         </div>
      </section>
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                  <div className="row">
                     <h5>SERVICES</h5>
                     <h2><span>Domain compliances</span> & governance</h2>
                     <p>Business Houses are facing increasing pressure to improve their overall security posture. Volumes of regulations and stakeholder demands for transparency, accountability, enterprise risk management, and optimized performance are now the norm. A reactionary response isn’t enough. Success depends on proactively balancing risk and opportunity across the entire business environment to respond rapidly to constant change. This as a discipline brings together focus areas across corporate governance, enterprise risk management, and corporate compliance. The aim of an effective DC& G strategy is to ensure those right efficiencies are brought in and more effective information sharing and reporting mechanisms are enabled through specialized programs.
                     </p>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      {/* program section start */}
      <section className='program-sections'>
         <div className="thisPractices">
            <div className="container">
               <img src={DomianIMg} alt="ncnbdjcn"/>
            </div>
         </div>
      </section>
      {/* program section end */}
      {/* program form Inner start */}
      <section className='Program-form'>
         <DomainCompliancesForm/>
      </section>
      {/* program form end */}

      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default Domain;