import React from 'react'
import {   FaLinkedin,FaEnvelope } from 'react-icons/fa';
import '../Styles/Footer.css'

function Careers() {
return (
<div className="Apps">
 {/* <!------footer start--------> */}
 <div className="footer-bg">
	<div className="container">		
		<div className="footer-box">
       <div className="row">
		   <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
			  <div className="first">
				  <h4>Address</h4>
				  <p><a href="mailto:info@brixnet.in">info@brixnet.in</a></p>
				  {/* <p><a href="#">sme@brixnet.in</a></p> */}
			  </div>
		   </div>
		   <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
			  <div className="second">
				  <h4>Menu</h4>
				  <ul>
					  <li><a href="/">Home</a></li>
					  <li><a href="/about">About Us</a></li>
					  <li><a href="/advanced-technology-domain">Services</a></li>
					  <li><a href="/alliances">Our Alliances</a></li>
					  <li><a href="/testimonials">Testimonial</a></li>
					  <li><a href="/careers">Careers</a></li>
					  <li><a href="/contact">Contact Us</a></li>
				  </ul>
			  </div>
		   </div>
		   <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
			  <div className="second">
				  <h4>Work With Us</h4>
				  <a a href="/careers"><p>Become an instructor</p></a>
				  <a href="/alliances"><p>Become an affiliate</p></a>
				  <div className="offcanvas-body">
                  <ul className="social-media">
                 
                     <li>
                        <a className="fb-ic mr-3" href="#">
                           <FaLinkedin />
                        </a>
                     </li>
                     <li>
                        <a className="fb-ic mr-3" href="mailto:info@brixnet.in">
                           <FaEnvelope />
                        </a>
                     </li>
                  </ul>
               </div>
			  </div>
		   </div>
		   </div>
	   </div>
	   <h6>© 2024 BRiX Network Pvt Ltd. All rights reserved.</h6>
	</div>
</div>
{/* <!------footer end--------> */}
   
</div>
);
}
export default Careers;