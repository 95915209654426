import React from 'react'
import '../Styles/Alliances.css'
import AlliancesImg from "../assets/images/our-alliances-authorized-logo.jpg"
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import BottomCarousel from '../Components/BottomCarousel'


function Alliances() {
  
return (
<div className="App">
<header className="header">
    
      <section className='backgroung-image-alliances'>
       <Navbar/>
      <div className='header-about-content'>
         <h1>A VENTURE WITH OUR PARTNERS</h1>
      </div>
      </section>
   </header>
   <main className="main">
        <section className='containers about-success container'>
             <div className='about-success-work'>
             <h2 className="what-we-dosss"><span className="what-we-dossss">OUR</span> ALLIANCES</h2>
             <p>Our partners are more than alliances; they're gateways to <span className="what-we-dosssss">limitless potential.</span></p>
             <p>Experience the exponential growth as we combine forces to navigate the complexities of today's market.</p>
             <p>Our strategic partnerships open doors to unparalleled opportunities, empowering businesses to thrive in today's landscape. </p>
             <hr className='hear-underline'></hr>
             <div className='container'>
             <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
                <h2 style={{textAlign: 'left'}}>SoSE</h2>
                <p>A leader in delivering noetic science, bringing the globe into a transformational vector.Time and energy are the primitive by-product of active learning and then it moves towards orderliness which is essential for brewing IQ, enabling this aspect through a profound technique spreads fragrance to our personality, enjoy the transformation by witnessing the delta, enabling this to the millennials to mitigate and overcome frictions in life is what we devote to deliver.</p>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
                <h2 style={{textAlign: 'left'}}>SNA</h2>
                <p>Savant Networks Academy a lineage of BRIX Networks (P) Ltd to foster Industry-Academia touch through our unique ECOSYSTEM and DNA CULTURE</p>
            </div>
             </div>
             </div>
             <div className="innerContent">
                <div className="ourAllienceOutses">
                     <div className="container">
                          <div className="ourAllienceContents">
                              <img src={AlliancesImg}/></div>
                         </div>
                      </div>
                </div>
             </div>
        </section>
    {/* Brand Section Start */}
    <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
{/* <!------footer end--------> */}
</div>
);
}
export default Alliances;