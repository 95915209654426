import React, { useState } from 'react';
import '../Styles/SoseMenu.css'
import nocPiechat from '../assets/images/noetc-science-img.png'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import DiagrameImg from '../assets/images/diagrame-img.png'
import BottomCarousel from '../Components/BottomCarousel'
import SkillMenuBar from '../Components/SkillMenuBar';
import LearningDevelopmentForm from '../Components/LearningDevelopmentForm';
function SoseMenu() {
 
  
return (
<div className="Apps">
   <header className="header">
      <section className='backgroung-image-noeticScience'>
         <Navbar/>
         <div className='header-Careers-contents'>
            <h1>WISH YOU JOIN US?</h1>
            <h2>SHARE YOUR PART</h2>
         </div>
      </section>
       {/* menu Start */}
       <SkillMenuBar/>
       {/* Header Menu End */}
   </header>
   <main className="main">
      <section className='container about-success'>
         <div className='Testimonials-success-work'>
            {/* <!------what start--------> */}
            <div className="testimonials-bg">
               <div className="container">
                  <div className="row">
                     <h5> SERVICES</h5>
                     <h2><span>noetic</span> Science</h2>
                     <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                           <p>The word 'noetic' refers to "inner knowledge" or "intuitive consciousness", Often referred to as 'the science of what connects us', Noetic Science is a relatively new field of methodical investigation into human consciousness. But what does that mean in terms of how we live our lives and why does it matter?</p>
                           <p>Well, consciousness is an awareness of how individuals perceive and interpret their environment, which extends to beliefs, intentions, attitudes, emotions, and all aspects of subjective experience. Our master expert will help you co-write the pages you will be authoring called life. It helps you to recalibrate your direction, habits of lower frequencies and their effects and will help you reach a higher level of understanding the difference in-between. You will be mastering, taking responsibility and making a dedicated commitment to your transformation. 
                           </p>
                        </div>
                        <div className=' img-width noc-img col-lg-6 col-md-12 col-sm-12'>
                           <img src={nocPiechat}/>
                        </div>
                     </div>
                     <p>I You will be able to delve deep into a state of pure intelligence – a microscopic self-observation that will make you realize that the world is coming out of you and not at you. You will become mindful of your internal self & experience an awareness which leads to self-empowerment. 
                     </p>
                     <p className='para-highlight'>Reaction versus responding and lead yourself to spontaneity | Compartmentalization of delivery.</p>
                  </div>
               </div>
            </div>
            {/* <!------success end--------> */}
         </div>
      </section>
      
      {/* program section start */}
      <section className='program-sections'>
         <div className="thisPractices">
            <h4>This practice is a transformative and profound technique that can model your success.<br></br>
               The outcome of this science is
            </h4>
            <div className="container">
               <img src={DiagrameImg} alt="ncnbdjcn"/>
            </div>
         </div>
      </section>
      {/* program section end */}
      {/* program form Inner start */}
      <LearningDevelopmentForm/>
      {/* program form end */}
      {/* Brand Section Start */}
      <BottomCarousel/>
      {/* Brand Section end */}
   </main>
   {/* <!------footer start--------> */}
   <Footer/>
   {/* <!------footer end--------> */}
</div>
);
}
export default SoseMenu;