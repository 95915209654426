import React from "react";
import emailjs from "emailjs-com";
import { useState } from "react";
import axios from "axios";
import '../Styles/AdvancedTechnology.css'

const AdvancedTechnologyform = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState();
    const [message, setMessage] = useState();
    const [designation, setDesignation] = useState();
    const [company, setCompany] = useState();
  
    const [technology, setTechnology] = useState([]);
    const [domain,setDomain]=useState([]);
    const [development,setDevelopment]=useState([]);
    const [service,setService]=useState([]);
  
    const handleTechnology = (e) => {
      if (e.target.checked) {
        setTechnology([...technology, e.target.value]);
      } else {
        setTechnology(technology.filter((val) => val !== e.target.value));
      }
    };
  
    const handleDomain = (e) => {
     if (e.target.checked) {
       setDomain([...domain, e.target.value]);
     } else {
       setDomain(domain.filter((val) => val !== e.target.value));
     }
   };
  
   const handleDevelopment = (e) => {
     if (e.target.checked) {
       setDevelopment([...development, e.target.value]);
     } else {
       setDevelopment(development.filter((val) => val !== e.target.value));
     }
   };
  
   const handleService = (e) => {
     if (e.target.checked) {
       setService([...service, e.target.value]);
     } else {
       setService(service.filter((val) => val !== e.target.value));
     }
   };
  
  
    const handleSubmit = async () => {
      console.log(name);
      await axios.post("http://localhost:5000/api/learning", {
        name: name,
        mobile: mobile,
        email: email,
        message: message,
        designation: designation,
        company: company,
        technology: technology,
        domain:domain,
        development:development,
        service:service,
  
      });
    };
  return (
    <section className="Program-form">
    <div className="programForm">
      <div className="container">
        <h2>
          <span>To Know More</span> About The Program
        </h2>
        <p>
          Please fill the following details. One of our subject matter <br />
          experts will revert to you soon.
        </p>
        <form>
          <div className="programFormInner">
            <div className="col-md-4">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name*"
                  className="text-input form-control"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  placeholder="Designation:*"
                  className="text-input form-control"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="company"
                  name="company"
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company"
                  className="text-input form-control"
                  required
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group col-md-6">
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email*"
                  className="text-input form-control"
                  required
                />
              </div>
              <div className="form-group col-md-6 phone-in">
                <input
                  type="text"
                  id="phone"
                  maxlength="10"
                  pattern="[0-9]{10}"
                  name="phone"
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Phone Number*"
                  className="text-input form-control"
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <textarea
                  name="message"
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message"
                  className="text-input form-control"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="programFormInner">
            <div className="row">
              <div className="col-md-6">
                <fieldset>
                  <legend>Advance Technology Domain</legend>
                  <ul>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Enablement Program"
                          name=""
                          onChange={handleTechnology}
                        />{" "}
                        <span>Enablement Program</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Vendor neutral Program"
                          name="projects[]"
                          onChange={handleTechnology}
                        />{" "}
                        <span>Vendor neutral Program</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="OEMs enablement | Certification Program"
                          name="projects[]"
                          onChange={handleTechnology}
                        />{" "}
                        <span>OEMs enablement|Certification Program</span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input
                          type="checkbox"
                          value="Cloud | SDx | Infrastructure automation tool Program"
                          name="projects[]"
                          onChange={handleTechnology}
                        />{" "}
                        <span>Cloud | Infrastructure automation program</span>
                      </label>
                    </li>
                  </ul>
                </fieldset>
              </div>
              <div className="col-md-6">
            
              </div>
              
            </div>
          
          </div>
          <div className="programFormInner">
          
            <div className="col-md-6 text-align-left">
              <button
                name="send"
                type="submit"
                className="btn btn-default submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  )
}

export default AdvancedTechnologyform